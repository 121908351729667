<div>
  <div>
    <div class="flex-space-between" style="padding: 10px 5px 0 4px">
      <div class="button-container">
        <span style="margin-right: 10px; flex-shrink: 0">
          <ion-button
            class="btn-small"
            [fill]="filter === 'all' ? 'solid' : 'clear'"
            [color]="filter === 'all' ? 'light' : 'dark'"
            (click)="filter = 'all'; getMessages()"
          >
            All
          </ion-button>
          <ion-button
            class="btn-small"
            [fill]="filter === 'draft' ? 'solid' : 'clear'"
            [color]="filter === 'draft' ? 'light' : 'dark'"
            (click)="filter = 'draft'; getMessages()"
          >
            Draft
          </ion-button>
          <ion-button
            class="btn-small"
            [fill]="filter === 'scheduled' ? 'solid' : 'clear'"
            [color]="filter === 'scheduled' ? 'light' : 'dark'"
            (click)="filter = 'scheduled'; getMessages()"
          >
            Scheduled
          </ion-button>
          <ion-button
            class="btn-small"
            [fill]="filter === 'sent' ? 'solid' : 'clear'"
            [color]="filter === 'sent' ? 'light' : 'dark'"
            (click)="filter = 'sent'; getMessages()"
          >
            Sent
          </ion-button>
        </span>
        <ion-spinner
          name="dots"
          [hidden]="!isLoading"
          style="opacity: 0.2; margin-left: 20px; margin-top: 6px"
        ></ion-spinner>
      </div>
      <div class="button-container">
        <ion-button (click)="newMessage()" class="btn-small">
          <ion-icon name="mail"></ion-icon>
          <span class="text-with-left-icon hidden-sm"> Compose email </span>
        </ion-button>
        <ion-button (click)="newSlackMessage()" class="btn-small">
          <ion-icon name="logo-slack"></ion-icon>
          <span class="text-with-left-icon hidden-sm"> Compose Slack broadcast </span>
        </ion-button>
      </div>
    </div>
  </div>

  <ion-card class="outline-card">
    <ngx-datatable
      class="material fullscreen datatable-row-borders"
      [columnMode]="ColumnMode.force"
      [limit]="100"
      [headerHeight]="44"
      [footerHeight]="50"
      [rowHeight]="50"
      [scrollbarH]="true"
      [rows]="messages"
      [reorderable]="true"
      [sorts]="[{ prop: 'sendAt', dir: 'desc' }]"
      [hidden]="!messages?.length"
    >
      <ngx-datatable-column name="Subject" [minWidth]="240" [width]="240" [sortable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <a (click)="editMessage(row)" class="row-title row-link">{{ row.subject || '(no subject)' }}</a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Notes" [minWidth]="240" [width]="240" [sortable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <a (click)="editNotes(row)" style="margin-left: 5px" class="row-link">
            <ion-icon name="create-outline" class="padded-icon"></ion-icon>
            <i *ngIf="!row.notes">Add notes</i>
          </a>
          <span (click)="editNotes(row)">{{ row.notes }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Status" [width]="120" [sortable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <ion-badge color="success" *ngIf="value === 'sent'">Sent </ion-badge>
          <ion-badge color="light" *ngIf="value === 'draft'">Draft </ion-badge>
          <ion-badge color="warning" *ngIf="value === 'scheduled'"
            >{{ row.runAt ? 'Scheduled' : 'Sending' }}
          </ion-badge>
          <ion-badge color="danger" *ngIf="value === 'failed'" (click)="showError(row)"
            >Failed
            <ion-icon
              name="information-circle"
              class="icon-padded-left clickable"
              *ngIf="row.error"
              ngxTippy
              [tippyProps]="{
                arrow: false,
                placement: 'bottom'
              }"
              data-tippy-content="Click to view error"
            >
            </ion-icon>
          </ion-badge>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Recipients" [width]="120" [sortable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <ion-badge color="light">
            <span
              ngxTippy
              [tippyProps]="{
                arrow: false,
                placement: 'bottom'
              }"
              data-tippy-content="Total customer channels"
            >
              <ion-icon name="business"></ion-icon>
              {{ row.groupCount }}
            </span>
            <span style="margin: 0 5px; opacity: 0.7">•</span>
            <span
              ngxTippy
              [tippyProps]="{
                arrow: false,
                placement: 'bottom'
              }"
              data-tippy-content="Total people reached"
            >
              <ion-icon name="people"></ion-icon>
              {{ row.peopleCount }}
            </span>
          </ion-badge>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Message type" [width]="120" [sortable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span *ngIf="row.deliveryMethod === 'email'">
            <ion-icon name="mail-outline" class="padded-icon"></ion-icon><strong>Email</strong>
          </span>
          <span *ngIf="row.deliveryMethod === 'slack'">
            <ion-icon name="logo-slack" class="padded-icon"></ion-icon><strong>Slack</strong>
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Send at" prop="sendAt" [minWidth]="160" [width]="160" [sortable]="true">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          {{ value | date: 'MMM d yyyy h:mm a' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Actions" [minWidth]="160" [width]="160" [sortable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <a (click)="viewAnalytics(row)" style="margin-left: 5px">
            <ion-icon name="stats-chart" class="padded-icon"></ion-icon>
            Analytics
          </a>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <div class="empty-state-container" style="height: 80vh" [hidden]="messages?.length">
      <span class="placeholder-emoji">
        <ion-icon name="send-outline"></ion-icon>
      </span>
      <h1 class="placeholder-title">{{ filter === 'all' ? 'Bulk Message Your Slack channels' : 'No messages yet' }}</h1>
      <p>Create your own outbound messaging through Slack or email</p>
      <p>Not sure where to start? Check out some of our <a (click)="viewTemplates()">pre-made templates</a>.</p>
    </div>
  </ion-card>
</div>
